import React from "react";
import Helmet from "react-helmet"
import ReactGA from 'react-ga'
import 'styles/global.scss';

import { Header } from 'sawada.components/Header';
import { ButtonTel  as BottomButton} from 'sawada.components/ButtonTel';

ReactGA.initialize('UA-52229310-2');

export default ({ path, pageContext, children }) => {
  ReactGA.pageview(path)

  return (
    <>
      <Header subtitle="ご予約"/>
      <div>{ children }</div>
      <BottomButton subtitle="電話予約" />
      <Helmet
          htmlAttributes={{
              lang: 'ja',
          }}
          title="さわだ整骨院 | 札幌　円山公園 | 腰痛・肩こり・骨盤矯正・骨折・交通事故"
          meta={[
              { name: 'description', content: '骨折・肩こり・骨盤矯正・腰痛など、患者様の症状に合うオーダーメイド施術をご提案。札幌市中央区、円山公園駅すぐの「さわだ整骨院」。国家資格の柔道整体師が施術するので安心。交通事故治療対応、各種保険取り扱い。オイルマッサージもおすすめ。' },
              { name: 'keywords', content: '整骨院,円山公園,札幌,交通事故,保険,オイルマッサージ' },
          ]}
      />
    </>
  )
}

